/* istanbul ignore file */
import { TrackingLogger } from './TrackingLogger';

const VISIBILITY_PERCENTAGE_GAP = 3; // track every 5% visibility change
const VISIBILITY_THRESHOLD = 0.33; // Sections with less than 33% visibility are not tracked.
const TRACKING_INTERVAL = 3000;

type TrackingDatum = { startedAt?: number; intervalId?: NodeJS.Timeout; index: number };

function startTracking(datum: TrackingDatum, dispatch: (time: number) => void) {
  if (datum.intervalId) return;
  datum.startedAt = performance.now();

  datum.intervalId = setInterval(() => {
    const now = performance.now();
    dispatch(now - (datum.startedAt ?? now));
    datum.startedAt = now;
  }, TRACKING_INTERVAL);

  TrackingLogger.log(`observeVisibility: start tracking target #${datum.index}`);
}

function stopTracking(datum: TrackingDatum) {
  if (datum.intervalId) {
    clearInterval(datum.intervalId);
    datum.intervalId = undefined;
    datum.startedAt = undefined;

    TrackingLogger.log(`observeVisibility: stop tracking target #${datum.index}`);
  }
}

export function observeVisibility({
  root,
  targets,
  dispatch,
}: {
  root: Element;
  targets: Element[];
  dispatch: (id: string, time: number) => void;
}) {
  const data = new Map<string, TrackingDatum>(targets.map(({ id }, index) => [id, { index: index + 1 }]));

  const observer = new IntersectionObserver(
    (entry) => {
      entry.forEach(({ target, intersectionRatio: visibility }: IntersectionObserverEntry) => {
        const datum = data.get(target.id);
        if (!datum) return;

        const callback = (time: number) => {
          TrackingLogger.log(`IntersectionObserver tracks ${target.id.substring(0, 5)}... for ${Math.round(time)}ms`);
          dispatch(target.id, time);
        };

        if (visibility >= VISIBILITY_THRESHOLD) {
          startTracking(datum, callback);
        } else {
          stopTracking(datum);
        }
      });
    },
    {
      root,
      threshold: Array.from({ length: 100 / VISIBILITY_PERCENTAGE_GAP + 1 }, (_, i) => (i * VISIBILITY_PERCENTAGE_GAP) / 100),
    }
  );

  targets
    .map((target) => {
      target.id = target.previousElementSibling?.textContent ?? '';
      return target;
    })
    .forEach((target) => observer.observe(target));

  return {
    teardown: () => {
      data.forEach(stopTracking);
      observer.disconnect();
    },
  };
}
