import { FormControl, IconButton, Stack, TextField } from '@mui/material';
import { Control, Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import { MaskedInputProps } from '../billing/MaskedInputs';
import { Remove, Add } from '@mui/icons-material';

interface IncrementerProps<T extends FieldValues> {
  id: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  maskInputComponent?: React.ForwardRefExoticComponent<MaskedInputProps & React.RefAttributes<HTMLInputElement>>;
  control: Control<T>;
  rules?: Omit<RegisterOptions<T, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  onUpdate?: (value: number) => void;
}

function Incrementer<T extends FieldValues>(props: IncrementerProps<T>) {
  const { id, placeholder = '', maskInputComponent, disabled = false, control, rules, onUpdate } = props;

  return (
    <Controller
      name={id}
      control={control}
      rules={rules}
      render={({ field, field: { value }, fieldState: { error } }) => (
        <FormControl key={`form-control-${id}`} error={!!error} sx={{ flex: '1 0 0', overflow: 'visible' }}>
          <Stack direction="row" gap={1}>
            <IconButton
              onClick={() => {
                const newValue = parseInt(value) - 1;
                field.onChange(newValue);
                if (onUpdate) {
                  onUpdate(newValue);
                }
              }}
              disabled={parseInt(value) <= 0}
            >
              <Remove />
            </IconButton>
            <TextField
              id={id}
              disabled={disabled}
              error={!!error}
              size="medium"
              inputProps={{
                inputComponent: (maskInputComponent as any) || undefined,
                readOnly: true,
                style: {
                  textAlign: 'center',
                },
              }}
              sx={{
                flex: '1 0 0',
                maxWidth: '60px',
              }}
              placeholder={placeholder || undefined}
              value={value}
            />
            <IconButton
              onClick={() => {
                const newValue = parseInt(value) + 1;
                field.onChange(newValue);
                if (onUpdate) {
                  onUpdate(newValue);
                }
              }}
            >
              <Add />
            </IconButton>
          </Stack>
        </FormControl>
      )}
    />
  );
}

export default Incrementer;
