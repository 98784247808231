import { GridCellParams } from '@mui/x-data-grid-pro';
import { ColumnTypeIdentifier, RowTypesIdentifier } from '../../../../grid/reduxStore/table.types';
import OptionalRowInput from './OptionalRowInput';
import { Tooltip } from '@mui/material';

export const getRoundedValueTooltipTitle = ({
  value,
  numberOfDecimalPlaces,
}: {
  value: number;
  numberOfDecimalPlaces?: number;
}): string => {
  const numValue = Number(value);
  const actualDecimalPlaces = numValue.toString().includes('.') ? numValue.toString().split('.')[1].length : 0;
  const isRounded =
    numberOfDecimalPlaces &&
    (actualDecimalPlaces > numberOfDecimalPlaces || (numberOfDecimalPlaces === 5 && actualDecimalPlaces === numberOfDecimalPlaces));

  const shouldShowTooltip = value !== undefined && value !== null && !isNaN(numValue) && isRounded;

  return shouldShowTooltip ? `Number rounded from ${value}` : '';
};

export const CustomTableCell = ({
  params,
  value,
  numberOfDecimalPlaces,
}: {
  params: GridCellParams;
  value: string;
  numberOfDecimalPlaces?: number;
}) => {
  if (!params) {
    return <></>;
  }

  const rowType = params?.row?.rowType?.toLowerCase();
  const columnType = params?.colDef?.['columnType'] ? params.colDef['columnType'].toString().toLowerCase() : '';
  const isRenderInputActive =
    params.colDef['columnType'] === ColumnTypeIdentifier.OPTIONAL && params.row.rowType === RowTypesIdentifier.BODY;
  const valueDisplay = value || '';
  const isRowOptional = params?.row?.isRowOptional ?? false;
  const isOptionalToggleAvailable = params?.row?.isOptionalToggleAvailable && isRenderInputActive;
  const tooltipTitle = getRoundedValueTooltipTitle({ value: params.value as number, numberOfDecimalPlaces });

  return (
    <Tooltip title={tooltipTitle} placement="bottom" followCursor data-testid="render-cell-tooltip">
      <span data-testid="render-cell-test" data-testid-row-type={rowType} data-testid-column-type={columnType}>
        {isOptionalToggleAvailable ? <OptionalRowInput isRowOptional={isRowOptional} id={params.id as string} /> : valueDisplay}
      </span>
    </Tooltip>
  );
};

export const CustomTableCellInPreview = ({
  params,
  value,
  numberOfDecimalPlaces,
}: {
  params: any;
  value: string;
  children?: React.ReactNode;
  numberOfDecimalPlaces?: number;
}) => {
  const rowType = params?.row?.rowType?.toLowerCase();
  const columnType = params?.colDef?.columnType?.toString().toLowerCase();
  const valueDisplay = value || '';
  const tooltipTitle = getRoundedValueTooltipTitle({ value: params.value, numberOfDecimalPlaces });
  return (
    <Tooltip title={tooltipTitle} placement="bottom" followCursor data-testid="render-cell-tooltip">
      <span data-testid="render-cell-test-preview" data-testid-row-type={rowType} data-testid-column-type={columnType}>
        {valueDisplay}
      </span>
    </Tooltip>
  );
};
